<template>
  <TransitionRoot appear :show="true" as="template">
    <ClientOnly>
      <Dialog
        as="div"
        :open="true"
        class="relative z-50"
        @close="emit('close')"
      >
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-700 bg-opacity-70 backdrop-blur-sm"
          />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-lg transform overflow-hidden rounded bg-white text-left align-middle shadow-lg transition-all"
              >
                <slot :close="close" />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </ClientOnly>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  TransitionChild,
  DialogPanel,
  TransitionRoot,
} from "@headlessui/vue";

const emit = defineEmits(["close"]);

function close() {
  emit("close");
}
</script>
